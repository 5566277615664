<div class="bg-light min-vh-100 d-flex flex-row align-items-center bg-login-img">
  <c-container>
    <c-row class="justify-content-center">
      <c-col lg="6" xl="4" xxl="5">
        <c-card-group>
          <c-card class="p-4">
            <c-card-body>
              <form [formGroup]="loginForm">
                <h1>Login</h1>
                <p class="text-medium-emphasis">Digite as informações abaixo para acessar</p>
                <c-input-group class="mb-3">
                  <span cInputGroupText>
                    <svg cIcon name="cilUser"></svg>
                  </span>
                  <input 
                    autoComplete="username" 
                    formControlName="emailControl" 
                    placeholder="E-mail"
                    class="form-control"
                    [ngClass]="{'is-invalid': errorMessage(emailControl) !== null}"
                  />
                  <div *ngIf="errorMessage(emailControl) !== null" class="invalid-feedback">
                    {{ errorMessage(emailControl) }}
                  </div>
                </c-input-group>
                <c-input-group class="mb-4">
                  <span cInputGroupText>
                    <svg cIcon name="cilLockLocked"></svg>
                  </span>
                  <input
                    autoComplete="current-password"
                    formControlName="senhaControl"
                    class="form-control"
                    placeholder="Senha"
                    type="password"
                    [ngClass]="{'is-invalid': errorMessage(senhaControl) !== null}"
                  />
                  <div *ngIf="errorMessage(senhaControl) !== null" class="invalid-feedback">
                    {{ errorMessage(senhaControl) }}
                  </div>
                </c-input-group>
                <c-row>
                  <c-col class="text-right" xs="6">
                    <button cButton class="px-0" color="link" [routerLink]="['/reset-password']">
                      Esqueceu sua senha?
                    </button>
                  </c-col>
                  <c-col xs="6">
                    <button cButton class="px-4 float-button-right" color="primary" type="submit" (click)="onLogin()" [loading]="loading" cLoadingButton [disabled]="loginForm.invalid">
                      <svg cIcon name="cilInput" *ngIf="!loading"></svg>
                      Entrar
                    </button>
                  </c-col>                  
                </c-row>
              </form>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
    <c-row class="justify-content-center mt-2">
      <c-col md="8" class="color-version">
        <p class="text-center">
          Versão: {{ version }}
        </p>
      </c-col>      
    </c-row>
  </c-container>
</div>
