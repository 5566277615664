import { Component, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '@coreui/angular-pro';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '../../service/validation.service';

@Component({
  selector: 'observation-dialog',
  templateUrl: './observation-dialog.component.html',
  styleUrls: ['./observation-dialog.component.scss']
})
export class ObservationDialogComponent {

  public title : string = "";
  @ViewChild('confirmDialog') confirmDialog: ModalComponent | undefined;

  //FORM GROUP
  public form !: FormGroup;
  //FORM
  public observation  !: AbstractControl;

  constructor(
    private validationService : ValidationService,
    private activeModal       : NgbActiveModal,
    private formBuilder       : FormBuilder
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  /**
     * Montagem do formulário
     * @memberof EmailDialogComponent
     */
  buildForm(): void {    
    this.form = this.formBuilder.group({
      observation     : ['', Validators.compose([Validators.maxLength(1000)])]
    });

    this.observation = this.form.controls['observation'];
  }

  public decline() {
    this.activeModal.close();
  }

  public accept() {
    this.activeModal.close(this.observation.value ? this.observation.value : "");
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  /**
   * 
   * @param {AbstractControl} control
   * @returns 
   */
  errorMessage(control: AbstractControl) {
    return this.validationService.errorMessage(control);
  }

}
