<!--sidebar-->
<c-sidebar
  #sidebar1="cSidebar"
  class="d-print-none sidebar sidebar-fixed"
  id="sidebar1"
  visible
>
  <c-sidebar-brand
    [brandFull]="{
      src: 'assets/img/logotipo/logotipo_simbolo_branco.png',
      width: 46,
      height: 60,
      alt: 'SDS Logo'
    }"
    [brandNarrow]="{
      src: 'assets/img/logotipo/logotipo_simbolo_branco.png',
      width: 46,
      height: 60,
      alt: 'SDS Logo'
    }"
    [routerLink]="[]"
  >
  </c-sidebar-brand>

  <perfect-scrollbar [config]="perfectScrollbarConfig">
    <c-sidebar-nav [navItems]="navItems"dropdownMode="close"
  ></c-sidebar-nav>
  </perfect-scrollbar>

  <c-sidebar-toggler
    *ngIf="!sidebar1.narrow"
    toggle="unfoldable"
    cSidebarToggle="sidebar1"
  ></c-sidebar-toggler>
</c-sidebar>

<!--aside-->
<app-default-aside style="display: none;"></app-default-aside>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <!--app-header-->
  <app-default-header class="mb-4 d-print-none header header-sticky" position="sticky" sidebarId="sidebar1"></app-default-header>
  <!--app-body-->
  <div class="body flex-grow-1 px-3">
    <!-- <c-container breakpoint="lg" class="h-auto"> -->
      <router-outlet>
        <ngx-spinner type="ball-scale-multiple">
          <p style="color: white" > Carregando... </p>
        </ngx-spinner>
      </router-outlet>
    <!-- </c-container> -->
  </div>
  <!--app footer-->
  <app-default-footer></app-default-footer>
</div>
