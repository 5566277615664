import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CachingService } from './caching.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private path = environment.API_BASE_URL_CORE + '/permission';

  constructor(
    private http: HttpClient,
    private _c: CachingService) { }

  /**
     * Retornar todas as permissões do usuário
     * @param {boolean} cached
     * @returns {Observable<any>}
     * @memberof PermissionService
     */
  getAll(cached: boolean = true): Observable<any> {

    let url = this.path;

    if (cached && this._c.isCashed(url)) {
      return of(this._c.getData(url));
    }

    return this.http.get(url)
      .pipe(
        tap(this._c.setData(url))
      );
  }

  /**
   * Cria permissões
   * @param {any} data
   * @returns {Observable<any>}
   * @memberof PermissionService
   */
  create(data: any) {
    return this.http.post(this.path, data);
  }

  /**
   * Verifica a permissão pela lista passada
   * @param permissionList 
   * @param roles 
   * @returns 
   */
  hasRole(permissionList: Array<any>, roles: any[]): boolean {
    var result = false
    if (permissionList) {
      roles.forEach(role => {
        for (var i in permissionList) {
          if (permissionList[i] === role) {
            result = true
            break;
          }
        }
      })
      
    }
    return result
  }
  
}
