import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { jwt } from 'src/app/shared/constants/jwt';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (localStorage.getItem(jwt.JWT_KEY)) {
      return true;
    }

    this.router.navigate(['/login']);

    return true;
  }

  /**
     *
     * @returns {boolean}
     * @memberof AuthGuard
     */
  isAuthorized(): boolean {
    return localStorage.getItem(jwt.JWT_KEY) ? true : false;
  }

  /**
     *
     * @returns {string}
     * @memberof AuthGuard
     */
  getAuthorization(): string | null {
    return localStorage.getItem(jwt.JWT_KEY);
  }
  
}
