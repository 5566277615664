import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { GetUserLogger, SetUserLogger } from "./../action/user-logger.actions";
import { AccountService } from 'src/app/shared/service/account.service';

export class UserLoggerModel {
    user: any
}

@State<UserLoggerModel>({
    name: 'userLoggerState',
    defaults: {
        user: null
    }
})

@Injectable()
export class UserState {
    
    constructor(private _accountService: AccountService) { }

    @Selector()
    static selectStateData(state:UserLoggerModel){
        return state.user;
    }

    @Action(GetUserLogger)
    getDataFromState(ctx: StateContext<UserLoggerModel>) {
        ctx.getState().user;
    }

    @Action(SetUserLogger)
    addDataToState(ctx: StateContext<UserLoggerModel>, { payload }: SetUserLogger) {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            user: payload
        })
    }
}