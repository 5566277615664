import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'cnpj'
})
export class CnpjPipe implements PipeTransform {
  transform(value: string): string {

    if (!value && value.length < 14) {
      return value;
    }

    if (value.length == 14) {
      return  `${value.slice(0,2)}.${value.slice(2, 3)}.${value.slice(5, 3)}/${value.slice(8, 4)}-${value.slice(12)}`
    } else {
      return  value;
    }
  }
}