<div class="modal-header">
  <h5 class="modal-title">Envio de {{ title }}</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="form">   
    <div class="row mb-2">
      <div class="col-16">
        <label cLabel for="observation">Observação:</label>
        <textarea class="form-control" [ngClass]="{'is-invalid': errorMessage(observation) !== null}" formControlName="observation" id="observation" rows="3"
          placeholder="Digite uma observação"></textarea>
        <div *ngIf="errorMessage(observation) !== null" class="invalid-feedback">
          {{ errorMessage(observation) }}
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button cButton color="danger" (click)="decline()">
    <svg cIcon name="cilX"></svg>
    Cancelar
  </button>
  <button cButton color="primary" [disabled]="form.invalid" (click)="accept()">
    <svg cIcon name="cilCheckAlt"></svg>
    Enviar
  </button>
</div>