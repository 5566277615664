import { Pipe, PipeTransform } from '@angular/core';

import { Moment } from 'moment';
import * as moment from 'moment';

@Pipe({
  name: 'data'
})
export class DataPipe implements PipeTransform {

  /**
   * Método responsável por formatar a DATA.    
   * @param {string} data 
   * @param {string} formatOutput
   * @returns {string} 
   * @memberof DataPipe
   */
  transform(data: string, formatOutput?: string): string {  

    if (data == "N/D")  {
      return "N/D";
    }

    if (!formatOutput) {
      formatOutput = 'DD/MM/YYYY HH:mm:ss'
    }

    if (data) {
      data = data.replace(/T/gi, ' ').replace(/.[0-9]*$/, '');

      let date: Moment = moment(data, true);
      if (date.isValid()) {
        return date.format(formatOutput);
      }
    }
    
    return data;
  }
}