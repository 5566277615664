import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidationService } from 'src/app/shared/service/validation.service';
import { LoginService } from '../shared/login.service';
import { compareValidator } from 'src/app/shared/action/control-compare';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [LoginService],
})
export class ChangePasswordComponent implements OnInit {
  
  //FORM GROUP
  public changeForm!: FormGroup;
  //FORM
  public loading: boolean = false;
  public passwordControl!: AbstractControl;
  public confirmPasswordControl!: AbstractControl;
  //PARAMS
  public token: any = "";
  public code: any = "";
  public typePassword: string = "password";
  public typePasswordConfirm: string = "password";

  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.route.params.subscribe(params => {
      this.token = params['token'];
      this.code = params['code'];
      this.validateToken();
  });
  }

  /**
     * Montagem do formulário
     * @memberof ChangePasswordComponent
     */
  buildForm(): void {
    this.changeForm = this.formBuilder.group({
      passwordControl: ['', Validators.compose([Validators.required, compareValidator('confirmPasswordControl')])],
      confirmPasswordControl: ['', Validators.compose([Validators.required, compareValidator('passwordControl')])],
    });

    this.passwordControl = this.changeForm.controls['passwordControl'];
    this.confirmPasswordControl = this.changeForm.controls['confirmPasswordControl']; 
  }

  /**
     * Tratamento de erros do form
     * @param {AbstractControl} control
     * @returns
     * @memberof ChangePasswordComponent
     */
  errorMessage(control: AbstractControl) {
    return this.validationService.errorMessage(control);
  }

    /**
     * Executa o serviço que realizará a validção do token
     * @param
     * @returns
     * @memberof ValidateTokenComponent
     */
  validateToken(): void {
    this.loading = true;
    this.loginService.validateToken(this.token, this.code)
    .subscribe({ 
      next: (data: any) => {
        if (data.code == 0) {
          this.loading = false;
        }
        else if(data.message == "Invalid authentication") {
          this.loading = false;
          this.toastr.error("O Link está espirado. Realize uma nova requisição", "Atenção!");
          this.router.navigate(['/']);
        }
        else {
          this.loading = false;
          this.router.navigate(['/']);
        }
      }, 
      error: (erro: HttpErrorResponse) => { 
        this.loading = false;
        this.router.navigate(['/']);
      }
    }); 
      
  }

  changeVisiblePasswrod() {
    this.typePassword = this.typePassword == "text" ? "password" : "text";
  }

  changeVisibleConfirmPasswrod() {
    this.typePasswordConfirm = this.typePasswordConfirm == "text" ? "password" : "text";
  }

  /**
     * Executa o serviço que realizará a troca da senha
     * @param 
     * @returns
     * @memberof ChangePasswordComponent
     */
  onChange(): void {   
    this.loading = true;
    this.loginService.change(this.token, this.code, this.passwordControl.value)
    .subscribe({ 
      next: (data: any) => {
        if (data.code == 0) {
          this.loading = false;
          this.toastr.success("Sua senha foi atualizada com sucesso", "Sucesso!");
          this.router.navigate(['/']);
        }
        else if(data.message == "Invalid authentication") {
          this.loading = false;
          this.toastr.error("O Link está espirado. Realize uma nova requisição", "Atenção!");
          this.router.navigate(['/']);
        }
        else {
          this.loading = false;
          this.router.navigate(['/']);
        }
      }, 
      error: (erro: HttpErrorResponse) => { 
        this.loading = false;
        this.router.navigate(['/']);
      }
    }); 
  }
}
