import { Pipe, PipeTransform } from "@angular/core";
import { orderBy } from 'lodash';

@Pipe({
 name: "orderBy"
})
export class OrderByPipe implements PipeTransform {
    transform(array: any, sortBy: string, order?: string): any[] {
        if (order == "desc" || order == "asc") {   
            return orderBy(array, [sortBy], [order]);
        }
        return array;
    }
}