import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CachingService } from 'src/app/shared/service/caching.service';
import { jwt } from 'src/app/shared/constants/jwt';
import { NgxPermissionsService } from 'ngx-permissions';
import { Store } from '@ngxs/store';
import { UpdateData } from 'src/app/shared/action/update.data.actions';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private path = environment.API_BASE_URL_CORE + '/authenticate';
  private pathOut = this.path + '/signOut';
  private pathReset = this.path + '/password/reset';
  private pathValidateToken = this.path + '/password/validtoken';
  private pathChange = this.path + '/password/change';

  constructor(
    private http: HttpClient, 
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private _c: CachingService,
    private store: Store) { }

  /**
     * Faz autenticação do usuário
     * @param {string} email
     * @param {string} password
     * @returns {Observable<any>}
     * @memberof LoginService
     */
  login(email: string, password: string) {
    const creds = {
      email: email,
      password: password
    };
    

    return this.http.post(this.path, creds)
      .pipe(
        map(
          (data: any) => {
            this._c.reset();
            localStorage.setItem(jwt.JWT_KEY, data.data.token);
            localStorage.setItem(jwt.THEME_MODE, 'light');
            this.resetState()
          }
        ),
        catchError(err => {
          return of([]);
      }),
      );
  }

  /**
     * Reseta senha do usuário
     * @param {string} email
     * @returns {Observable<any>}
     * @memberof LoginService
     */
  reset(email: string, hostServer: string) {
    const creds = {
      Email: email,
      Host: hostServer
    };
    return this.http.post(this.pathReset, creds);
  }

  validateToken(token: string, code: string) {
    const creds = {
      Token: token,
      Code: code,
    };
    return this.http.post(this.pathValidateToken, creds);
  }

  change(token: string, code: string, newPassword: string) {
    const creds = {
      Token: token,
      Code: code,
      Password: newPassword
    };
    return this.http.post(this.pathChange, creds);
  }


  /**
   * Remove autenticação do usuário
   * @memberof LoginService
   */
  logout(): void {
    localStorage.removeItem(jwt.JWT_KEY);
    localStorage.removeItem(jwt.THEME_MODE);
    this._c.reset();
    this.http.post(this.pathOut, {});
    this.router.navigate(['/']);
    this.permissionsService.flushPermissions();
  }

  resetState() {
    this.store.dispatch(new UpdateData())
  }
}
