import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CachingService {

  private __cach = new Map();
  
  isCashed(url: string): boolean {
    return this.__cach.has(url);
  }

  getData(url: string): any {
    return this.__cach.get(url);
  }

  setData(url: string) {
    return (data: any) => {
      if (data && (data instanceof Error) === false) {
        this.__cach.set(url, data);
      }
    };
  }

  reset() {
    this.__cach.clear();
  }
}
