import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ObservationDialogComponent } from './../observation-dialog.component';

@Injectable()
export class ObservationDialogService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string = 'e-mail',
    dialogSize: 'sm'|'md'|'lg' = 'lg',
    dialogCentered: true | false = true
    ): Promise<string> {
    const modalRef = this.modalService.open(ObservationDialogComponent, { size: dialogSize, centered: dialogCentered });
    modalRef.componentInstance.title = title;
    return modalRef.result;
  }

}
