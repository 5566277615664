import { Pipe, PipeTransform } from '@angular/core';
 
@Pipe({
	name: 'valorVazio'
})
export class ValorVazioPipe implements PipeTransform {

	transform(valor: string): string {
        return valor ? valor : "N/A";       
	}
}