import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidationService } from 'src/app/shared/service/validation.service';
import { LoginService } from '../shared/login.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [LoginService]
})
export class ResetPasswordComponent implements OnInit {
  
  //FORM GROUP
  public resetForm!: FormGroup;
  //FORM
  public loading: boolean = false;
  public emailControl!: AbstractControl;

  public hostServer: any;

  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.buildForm();
    const protocol: string = window.location.protocol;
    const url: string = window.location.href;
    const host: string = new URL(url).host;
    this.hostServer = protocol + "//" + host;
  }

  /**
     * Montagem do formulário
     * @memberof ResetPasswordComponent
     */
  buildForm(): void {
    this.resetForm = this.formBuilder.group({
      emailControl: ['', Validators.compose([Validators.required])],
    });

    this.emailControl = this.resetForm.controls['emailControl'];
  }

  /**
     * Tratamento de erros do form
     * @param {AbstractControl} control
     * @returns
     * @memberof ResetPasswordComponent
     */
  errorMessage(control: AbstractControl) {
    return this.validationService.errorMessage(control);
  }

  /**
     * Tratamento de erros do form
     * @param {AbstractControl} control
     * @returns
     * @memberof ResetPasswordComponent
     */
  onReset(): void {   
    this.loginService.reset(this.emailControl.value, this.hostServer)
    .subscribe({ 
      next: (data: any) => {
        if (data.code == 0) {
          this.loading = false;
          this.toastr.success("Acesse seu email para prosseguir com a alteração da senha", "Sucesso!");
          this.router.navigate(['/']);
        }
        else {
          this.loading = false;
          this.toastr.error("Ocorreu um erro durante a sua solitação. Tente novamente", "Atenção!");
          this.router.navigate(['/']);
        }
      }, 
      error: (erro: HttpErrorResponse) => { 
        this.loading = false;
        this.toastr.error("Ocorreu um erro durante a sua solitação. Tente novamente", "Atenção!");
        this.router.navigate(['/']);
      }
    });
  }
}
