import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'status'
})
export class StatusPipe implements PipeTransform {

    /**
     * Método responsável por formatar o status.
     * @param {string} status 
     * @returns {string} 
     * @memberof StatusPipe
     */
    transform(status: boolean): string {

        if (status) {
            return "Ativo";
        } 

        return "Inativo";
    }
}