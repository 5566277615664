import { Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '@coreui/angular-pro';

@Component({
  selector: 'progress-dialog',
  templateUrl: './progress-dialog.component.html',
  styleUrls: ['./progress-dialog.component.scss']
})
export class ProgressDialogComponent {

  @ViewChild('progressDialog') progressDialog: ModalComponent | undefined;

  @Input() title: string | undefined;
  @Input() message: string | undefined;
  @Input() icon: string | undefined;
  @Input() qntTotalSend: number | undefined;
  public qntSendNow : number = 1;
  public progressValueNow : number = 0;
  
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
