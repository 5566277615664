import { Pipe, PipeTransform } from '@angular/core';
 
@Pipe({
	name: 'tipoAcao'
})
export class TipoAcaoPipe implements PipeTransform {

	transform(acao: number): string {

        switch (acao) {
            case 1: 
                return 'Inserção'
            case 2:
                return 'Atualização'
            case 3:
                return 'Remoção'
            default:
                return 'N/D'
        }
	}
}