<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <c-container>
      <c-row class="justify-content-center">
        <c-col md="8">
          <c-card-group>
            <c-card class="p-4">
              <c-card-body>
                <form [formGroup]="resetForm">
                  <h1>Redefinir Senha</h1>
                  <p class="text-medium-emphasis">Digite as informações abaixo para redifinir sua senha!</p>
                  <c-input-group class="mb-3">
                    <span cInputGroupText>
                      <svg cIcon name="cilUser"></svg>
                    </span>
                    <input 
                      autoComplete="username" 
                      formControlName="emailControl" 
                      placeholder="E-mail"
                      class="form-control"
                      [ngClass]="{'is-invalid': errorMessage(emailControl) !== null}"
                    />
                    <div *ngIf="errorMessage(emailControl) !== null" class="invalid-feedback">
                      {{ errorMessage(emailControl) }}
                    </div>
                  </c-input-group>
                  <c-row>
                    <c-col xs="12" class="d-flex justify-content-between">
                        <button cButton class="px-4" color="light" [routerLink]="['/login']">
                            Voltar
                        </button>
                        <button cButton class="px-4 float-button-right" color="primary" (click)="onReset()" [loading]="loading" cLoadingButton [disabled]="resetForm.invalid">
                          <svg cIcon name="cilInput" *ngIf="!loading"></svg>  
                          Redefinir
                        </button>
                    </c-col>            
                  </c-row>
                </form>
              </c-card-body>
            </c-card>
          </c-card-group>
        </c-col>
      </c-row>
    </c-container>
  </div>
  