<c-sidebar #aside1="cSidebar" class="d-print-none hide" colorScheme="light" id="aside1" overlaid placement="end"
  size="lg">
  <!--nav for tabs-->
  <c-sidebar-header class="bg-transparent p-0">
    <c-nav variant="underline">
      <c-nav-item>
        <a [active]="true" [cTabContent]="asideTabContent" [tabPaneIdx]="0" cNavLink [routerLink]="[]">
          <svg alt="CoreUI Icons List" cIcon name="cilList"></svg>
        </a>
      </c-nav-item>
      <c-nav-item class="ms-auto me-2 d-flex align-items-center">
        <!--aside toggler-->
        <button cButtonClose cSidebarToggle="aside1" class="me-2" toggle="visible"></button>
      </c-nav-item>
    </c-nav>
  </c-sidebar-header>

  <!--tab content-->
  <c-tab-content #asideTabContent="cTabContent" class="scroll-side-right min-vh-100">
    <c-tab-pane role="tabpanel" style="transition: opacity 150ms linear 0s">
      <ng-container *ngTemplateOutlet="tabPane1"></ng-container>
    </c-tab-pane>
  </c-tab-content>
</c-sidebar>

<!--tab pane templates-->
<ng-template #tabPane1>
  <div [flush]="true" cListGroup>
    <a [routerLink]="[]" cListGroupItem *ngFor="let insurance of insuranceList">
      <c-avatar class="float-start me-2" size="lg" src="./assets/img/logotipo/Simbolo.png"></c-avatar>
      <div class="text-truncate">{{ insurance.insuranceName }}</div>
      <div class="row">
        <div class="col-12 ps-0">
          <c-badge color="info" shape="rounded-pill"
            (click)="openInsurances(insurance?.insuranceID, lightHouseType.BLUE)" class="me-2">{{ insurance.blue
            }}</c-badge>
          <c-badge color="success" shape="rounded-pill"
            (click)="openInsurances(insurance?.insuranceID, lightHouseType.GREEN)" class="me-2">{{ insurance.green
            }}</c-badge>
          <c-badge color="warning" shape="rounded-pill"
            (click)="openInsurances(insurance?.insuranceID, lightHouseType.YELLOW)" class="me-2">{{ insurance.yellow
            }}</c-badge>
          <c-badge color="danger" shape="rounded-pill"
            (click)="openInsurances(insurance?.insuranceID, lightHouseType.RED)" class="me-2">{{ insurance.red
            }}</c-badge>
          <c-badge color="black" shape="rounded-pill"
            (click)="openInsurances(insurance?.insuranceID, lightHouseType.BLACK)" class="me-2">{{
            insurance.black }}</c-badge>
        </div>
      </div>
    </a>
  </div>
</ng-template>

<button #aside2Button cSidebarToggle="aside2" class="invisible" toggle="visible"></button>

<c-sidebar #aside2="cSidebar" class="d-print-none hide" colorScheme="light" id="aside2" overlaid placement="end"
  size="lg">
  <!--nav for tabs-->
  <c-sidebar-header class="bg-transparent p-0">
    <c-nav variant="underline">
      <c-nav-item>
        <a [active]="true" [cTabContent]="asideTabContent2" [tabPaneIdx]="0" cNavLink [routerLink]="[]">
          <svg alt="CoreUI Icons List" cIcon name="cilList"></svg>
        </a>
      </c-nav-item>
      <c-nav-item class="ms-auto me-2 d-flex align-items-center">
        <!--aside toggler-->
        <button cButtonClose cSidebarToggle="aside2" class="me-2" toggle="visible"></button>
      </c-nav-item>
    </c-nav>
  </c-sidebar-header>

  <!--tab content-->
  <c-tab-content #asideTabContent2="cTabContent" class="scroll-side-right min-vh-100">
    <c-tab-pane role="tabpanel" style="transition: opacity 150ms linear 0s">
      <ng-container *ngTemplateOutlet="tabPane2"></ng-container>
    </c-tab-pane>
  </c-tab-content>
</c-sidebar>

<!--tab pane templates-->
<ng-template #tabPane2 >
  <div [flush]="true" cListGroup>
    <a *ngFor="let sinister of sinisterList; index as i; last as isLast" [cBorder]="{ bottom: !isLast }" style="cursor: pointer;"
      (click)="openRouter(sinister)" cListGroupItem class="message">
      <!-- <div class="py-3 pb-3 float-start"> -->
      <div class="float-start">
        <c-avatar class="float-start me-2" size="lg" src="./assets/img/logotipo/Simbolo.png"></c-avatar>
      </div>
      <!-- <div>
        <small class="text-medium-emphasis">Lukasz Holeczek</small>
        <small class="text-medium-emphasis float-end mt-1">1:52 PM</small>
      </div> -->
      <div class="row p-0">
        <div class="col-12 p-0 font-size-card">
          <small class="text-truncate fw-semibold">Sinistro: </small>
          <small class="text-medium-emphasis">{{ sinister?.sinisterCode }}-{{ sinister?.sinisterVersion }}</small>
          <!-- <small class="text-medium-emphasis float-end mt-1">
            <small class="text-medium-emphasis me-3">
              <svg cIcon name="cilCalendar"></svg>
                {{ sinister?.receiptDate | data:'DD/MM/YYYY' }}
            </small>
          </small> -->
        </div>
      </div>
      <div class="row p-0 mb-2">
        <div class="col-7 p-0 font-size-card">
          <small class="text-truncate fw-semibold">Orçamento: </small>
          <small class="text-medium-emphasis">{{ sinister?.budgetNumber }}</small>
        </div>
        <div class="col-4 p-0 font-size-card text-nowrap">
          <small class="text-truncate fw-semibold ">Placa: </small>
          <small class="text-medium-emphasis">{{ sinister?.plate | plate }}</small>
        </div>
      </div>

      <div class="row p-0 font-size-card">
        <div class="col-4 p-0 ps-2">
          <small class="text-medium-emphasis mt-1">
            <small class="text-medium-emphasis">
              <svg cIcon name="cilCalendar"></svg>
                {{ sinister?.schedulingDate | data:'DD/MM/YYYY' }}
            </small>
          </small>
        </div>
        <div class="col-4 p-0" align="center">
          <span class="badge bg-secondary">
            {{ sinister?.regulationTypeName != null ? (sinister.regulationTypeName.length > 15)? (sinister.regulationTypeName | slice:0:15)+'..':(sinister.regulationTypeName) : 'N/D' }}
          </span>
        </div>
        <div class="col-4 p-0">
          <span class="badge bg-secondary float-end" style="margin-top: 0.2rem;" [style.background-color]="getColor(sinister?.lightHouse)">
            {{ sinister?.typeConclusion != null ? (sinister.typeConclusion.length > 15)? (sinister.typeConclusion | slice:0:15)+'..':(sinister.typeConclusion) : 'N/D' }}
          </span>
        </div>
      </div>
    </a>
  </div>
</ng-template>
