import { NgModule } from '@angular/core';
 import { ConfirmacaoPipe } from './confirmacao.pipe';
 import { DataPipe } from './data.pipe';
 import { PlatePipe } from './plate.pipe';
 import { StatusPipe } from './status.pipe';
 import { CpfPipe } from './cpf.pipe';
 import { CnpjPipe } from './cnpj.pipe';
 import { TipoAcaoPipe } from './tipo-acao.pipe'
 import { ValorVazioPipe } from './valor-vazio.pipe';
 import { OrderByPipe } from './order-by.pipe'
 import { ReplacePipe } from './replace.pipe';

 @NgModule({
     imports: [],
     declarations: [
        ConfirmacaoPipe,
        DataPipe,
        PlatePipe,
        StatusPipe,
        CpfPipe,
        CnpjPipe,
        TipoAcaoPipe,
        ValorVazioPipe,
        OrderByPipe,
        ReplacePipe
    ],
     exports: [
        ConfirmacaoPipe,
        DataPipe,
        PlatePipe,
        StatusPipe,
        CpfPipe,
        CnpjPipe,
        TipoAcaoPipe,
        ValorVazioPipe,
        OrderByPipe,
        ReplacePipe
    ],
 })

 export class PipeModule {} 