<div class="bg-light min-vh-100 d-flex flex-row align-items-center bg-login-img">
  <c-container>
    <c-row class="justify-content-center">
      <c-col lg="6" xl="4" xxl="5">
        <c-card-group>
          <c-card class="p-4">
            <c-card-body>
              <form [formGroup]="changeForm">
                <h1>Redefinir Senha</h1>
                <p class="text-medium-emphasis">Digite sua nova senha</p>
                <c-input-group class="mb-4">
                  <span cInputGroupText>
                    <svg cIcon name="cilLockLocked"></svg>
                  </span>
                  <input
                    autoComplete="current-password"
                    formControlName="passwordControl"
                    class="form-control"
                    placeholder="Senha"
                    [type]="typePassword"
                    [ngClass]="{'is-invalid': errorMessage(passwordControl) !== null}"
                    passwordStrength
                  />
                  <button cButton color="secondary" id="change" type="button" (click)="changeVisiblePasswrod()">
                    <svg cIcon [name]="typePassword == 'text' ? 'cidEye' : 'cidEyeSlash'"></svg>
                  </button>
                  <div *ngIf="errorMessage(passwordControl) !== null" class="invalid-feedback">
                    {{ errorMessage(passwordControl) }}
                  </div>
                </c-input-group>
                <p class="text-medium-emphasis">Confirme sua senha</p>
                <c-input-group class="mb-4">
                  <span cInputGroupText>
                    <svg cIcon name="cilLockLocked"></svg>
                  </span>
                  <input
                    autoComplete="current-password"
                    formControlName="confirmPasswordControl"
                    class="form-control"
                    placeholder="Senha"
                    [type]="typePasswordConfirm"
                    [ngClass]="{'is-invalid': errorMessage(confirmPasswordControl) !== null}"
                    passwordStrength
                  />
                  <button cButton color="secondary" id="change" type="button" (click)="changeVisibleConfirmPasswrod()">
                    <svg cIcon [name]="typePasswordConfirm == 'text' ? 'cidEye' : 'cidEyeSlash'"></svg>
                  </button>
                  <div *ngIf="errorMessage(confirmPasswordControl) !== null" class="invalid-feedback">
                    {{ errorMessage(confirmPasswordControl) }}
                  </div>
                </c-input-group>
                <c-row>
                  <c-col xs="6">
                    <button cButton color="primary" type="submit" (click)="onChange()" [loading]="loading" cLoadingButton [disabled]="changeForm.invalid">
                      <svg cIcon name="cilInput" *ngIf="!loading"></svg>
                      Redefinir
                    </button>
                  </c-col>                  
                </c-row>
              </form>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div>
