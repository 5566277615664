import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'cpf'
})
export class CpfPipe implements PipeTransform {
  transform(value: string): string {

    if (!value && value.length < 11) {
      return value;
    }

    if (value.length == 11) {
      return `${value.slice(0,3)}.${value.slice(3, 3)}.${value.slice(6, 3)}-${value.slice(-1)}`;
    } else {
      return  value;
    }
  }
}