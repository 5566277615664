<div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <!--<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="dismiss()"></button>-->
  </div>
  <div class="modal-body">
    <h4 *ngIf="message" style="text-align: center;">
      <svg *ngIf="icon" cIcon size="xl" [name]="icon"></svg>
      {{ message }}
    </h4>
    <div class="progress">
        <div 
            class="progress-bar progress-bar-striped progress-bar-animated" 
            role="progressbar"
            attr.aria-valuenow="{{progressValueNow}}" 
            aria-valuemin="0" 
            aria-valuemax="100"
            [style.width]="progressValueNow + '%' ">
        </div>
    </div>
    <br/>
    <p style="text-align: center;"> 
      Enviando {{ qntSendNow }} de {{ qntTotalSend }} registros
    </p>
  </div>