// password-strength.directive.ts
import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[passwordStrength]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordStrengthDirective),
      multi: true
    }
  ]
})
export class PasswordStrengthDirective implements Validator {

  validate(control: AbstractControl): { [key: string]: any } | null {
    const password = control.value;
    if (!password) {
      return null;
    }

    const score = this.calculateStrength(password);
    control.setErrors({ confirmPasswordControl: { errorMessage: 'A senha deve conter no mínimo 8 dígitos. Pelo menos 1 número. Pelo menos 1 caracter especial' }});
    return score ? null : { 'checkPassword': true };
  }

  private calculateStrength(password: string): boolean {
    if (!password) {
        return false;
    }

    // Expressão regular para validar a senha
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])(.{8,})$/;

    if (passwordRegex.test(password)) {
      return true; 
    }

    return false;
}

}