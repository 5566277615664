import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';

export function compareValidator(controlNameToCompare: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const controlToCompare = control.root.get(controlNameToCompare);
    if (controlToCompare && controlToCompare.value !== control.value) {
      controlToCompare.setErrors({ confirmPasswordControl: { errorMessage: 'As senhas não coincidem' }});
      return { 'compare': true };
    }
    controlToCompare?.setErrors(null);
    control?.setErrors(null);
    return null;
  };
}