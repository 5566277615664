import { Injectable } from '@angular/core';
import { jwt } from 'src/app/shared/constants/jwt';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor() { }

  /**
   * Recupera o JWT armazenado no localStorage.
   * 
   * @returns {string} O JWT armazenado no localStorage.
   */
  getToken(): string | null {
    return localStorage.getItem(jwt.JWT_KEY);
  }

  /**
   * Recupera o header do JWT armazenado no localStorage.
   * 
   * @returns {any} O header do JWT armazenado no localStorage.
   */
  getTokenHeader(): any {
    let rawToken = this.getToken();
    if (rawToken) {
        let rawHeader = rawToken.split('.')[0];
        let decodedRawHeader = atob(rawHeader);
        let decodedHeader = JSON.parse(decodedRawHeader);
        return decodedHeader;
    }
    return null;    
  }

  /**
   * Recupera o payload do JWT armazenado no localStorage.
   * 
   * @returns {any} O payload do JWT armazenado no localStorage.
   */
  getTokenPayload(): any {
    let rawToken = this.getToken();
    if (rawToken) {
        let rawPayload = rawToken.split('.')[1];
        let decodedRawPayload = atob(rawPayload);
        let decodedPayload = JSON.parse(decodedRawPayload);
        return decodedPayload;
    }

    return null;
  }
}
