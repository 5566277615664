import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegulationAutoService {

  private path = environment.API_BASE_URL_REGULATION_AUTO + '/regulationauto';

  constructor(
    private http: HttpClient) { }

  /**
   * 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  getAll() {
    return this.http.get(this.path);
  }

  /**
   * 
   * @param {number} limit 
   * @param {number} index 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  getAllPaging(limit: number, index: number) {
    return this.http.get(`${this.path}?limit=${limit}&index=${index}`);
  }

  /**
   * 
   * @param {string} id 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  getById(id: string) {
    return this.http.get(`${this.path}/${id}`);
  }

  /**
   * 
   * @param {string} id 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  getDetailsById(id: string) {
    return this.http.get(`${this.path}/withdetails/${id}`);
  }

  /**
   * 
   * @param sinister 
   * @param version 
   * @param budgetNumber 
   * @returns 
   */
  getBySinister(sinister: string, version: string, budgetNumber: string) {
    return this.http.get(`${this.path}/sinister/${sinister}/version/${version}/budgetnumber/${budgetNumber}`);
  }  

  /**
   * 
   * @param sinister 
   * @param version 
   * @param budgetNumber 
   * @returns 
   */
  getTypeBySinisterVersion(sinister: string, version: string, budgetNumber: string) {
    return this.http.get(`${this.path}/regulationtype/${sinister}/version/${version}/budgetnumber/${budgetNumber}`);
  }

  /**
   * 
   * @param {string} id 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  getSurveyWay(id: string) {
    return this.http.get(`${this.path}/${id}/surveyway`);
  }

  /**
   * 
   * @param {any} data 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  create(data: any) {
    return this.http.post(this.path, data);
  }

  /**
   * 
   * @param {any} data 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  update(data: any) {
    return this.http.put(this.path, data);
  }

  /**
   * 
   * @param {string} id 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  delete(id: string) {
    return this.http.delete(`${this.path}/${id}`);
  }

  /**
   * 
   * @param {any} data 
   * @param {number} limit 
   * @param {number} index 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  search(data: any, limit: number, index: number) {
    return this.http.post(`${this.path}/search?limit=${limit}&index=${index}`, data);
  }

  /**
   * 
   * @param {any} data 
   * @param {number} limit 
   * @param {number} index 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  search2(data: any, limit: number, index: number) {
    return this.http.post(`${this.path}/search2?limit=${limit}&index=${index}`, data);
  }


  /**
   * 
   * @param {any} data 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  export(data: any) {
    return this.http.post(`${this.path}/export`, data);
  }

  /**
   * 
   * @param {any} data 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  distribute(data: any) {
    return this.http.post(`${this.path}/distribute`, data);
  }

  /**
   * 
   * @param {string} id 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  returnRegulationToExpert(id: string) {
    return this.http.put(`${this.path}/${id}/expert`, null);
  }

  /**
   * 
   * @param {string} id 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  changeStageRegulationToDigitation(id: string) {
    return this.http.put(`${this.path}/${id}/stage/digitation`, null);
  }

  /**
   * 
   * @param {string} id 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  changeStageRegulationAccomplished(id: string) {
    return this.http.put(`${this.path}/${id}/stage/accomplished`, null);
  }

  /**
   * 
   * @param {string} id 
   * @returns {Observable<any>}
   * @memberof RegulationAutoService
   */
  changeStageRegulationCompleted(id: string) {
    return this.http.put(`${this.path}/${id}/stage/completed`, null);
  }
}
