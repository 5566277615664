import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CachingService } from './caching.service';
import { StringifyOptions } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private path = environment.API_BASE_URL_CORE + '/account';

  constructor(
    private http: HttpClient,
    private _c: CachingService) { }

  /**
     * Retornar usuário autenticado
     * @param {boolean} cached
     * @returns {Observable<any>}
     * @memberof AccountService
     */
  logged(cached: boolean = true): Observable<any> {

    let url = this.path + '/logged';

    if (cached && this._c.isCashed(url)) {
      return of(this._c.getData(url));
    }

    return this.http.get(url)
      .pipe(
        tap(this._c.setData(url))
      );
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  updateTheme(id: string, darkTheme: boolean) : Observable<any> {
    let url = `${this.path}/${id}/darktheme/${darkTheme}`;
    return this.http.put(url, {});
  }

  /**
     * 
     * @param {boolean} cached
     * @returns {Observable<any>}
     * @memberof AccountService
     */
  getAllAnalist(cached: boolean = true): Observable<any> {

    let url = this.path + '/analist';

    if (cached && this._c.isCashed(url)) {
      return of(this._c.getData(url));
    }

    return this.http.get(url)
      .pipe(
        tap(this._c.setData(url))
      );
  }

  /**
   * 
   * @param cached 
   * @returns 
   */
  getAllAccount(cached: boolean = false): Observable<any> {

    let url = this.path;

    if (cached && this._c.isCashed(url)) {
      return of(this._c.getData(url));
    }

    return this.http.get(url)
      .pipe(
        tap(this._c.setData(url))
      );
  }

  /**
   * 
   * @param limit 
   * @param index 
   * @returns 
   */
  getAllPaging(limit: number, index: number) {
    return this.http.get(`${this.path}?limit=${limit}&index=${index}`);
  }

  /**
   * 
   * @param name 
   * @param limit 
   * @param index 
   * @returns 
   */
  getAllByName(name: string, limit: number, index: number) {
    return this.http.get(`${this.path}/name/${name}?limit=${limit}&index=${index}`);
  }

  /**
   * 
   * @param limit 
   * @param index 
   * @param status
   * @returns 
   */
  getAllPagingStatus(limit: number, index: number, status: number) {
    return this.http.get(`${this.path}/status/${status}?limit=${limit}&index=${index}`);
  }

  /**
   * 
   * @param name 
   * @param limit 
   * @param index 
   * @param status
   * @returns 
   */
  getAllByNameStatus(name: string, limit: number, index: number, status: number) {
    return this.http.get(`${this.path}/name/${name}/status/${status}/?limit=${limit}&index=${index}`);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  create(data: any) : Observable<any> {
    return this.http.post(this.path, data);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  update(data: any) : Observable<any> {
    return this.http.put(this.path, data);
  }

  /**
   * 
   * @param id 
   * @returns 
   */
  delete(id: string) : Observable<any> {
    return this.http.delete(`${this.path}/${id}`);
  }

  /**
   * 
   * @param email 
   * @returns 
   */
  getByEmail(email: string) : Observable<any> {
    return this.http.get(`${this.path}/email/${email}`);
  }

  /**
   * 
   * @param data 
   * @param id 
   * @returns 
   */
  postDocument(data: any, id: string) : Observable<any> {
    return this.http.post(`${this.path}/document/account/${id}`, data);
  }

  /**
   * 
   * @param id 
   * @returns 
   */
  getDocuments(id: string) {
    return this.http.get(`${this.path}/documents/account/${id}`);
  }

  /**
   * 
   * @param limit 
   * @param index 
   * @returns 
   */
  getDocumentsPaging(id: string, limit: number, index: number) : Observable<any>{    
    return this.http.get(`${this.path}/documents/account/${id}?limit=${limit}&index=${index}`);
  }

  /**
   * 
   * @param id 
   * @returns 
   */
  getDocumentToDownload(id: string) {
    return this.http.get(`${this.path}/document/${id}`);
  }

  /**
   * 
   * @param id 
   * @returns 
   */
  deleteDocument(id: string) : Observable<any> {
    return this.http.delete(`${this.path}/document/${id}`);
  }

  /**
   * 
   * @param data 
   * @param id 
   * @param name 
   * @param email 
   * @param cellphone 
   * @returns 
   */
  updateProfile(data: any, id: string, name: string, email: string, cellphone: string) : Observable<any> {
    let url = `${this.path}/profile/account/${id}/name/${name}/email/${email}/cellphone/${cellphone}`;
    return this.http.put(url, data);
  }

}
