import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'plate'
})
export class PlatePipe implements PipeTransform {

    /**
     * Método responsável por formatar a placa.
     * @param {string} plate 
     * @returns {string} 
     * @memberof PlatePipe
     */
    transform(plate: string): string {

        if (plate == null || plate == undefined) {
            return "N/D";
        }

        if (plate && plate.length === 7) {
            return plate.substring(0,3).toUpperCase().concat("-").concat(plate.substring(3,7).toUpperCase());
        }

        return plate;
    }
}