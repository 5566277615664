import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LightHouseInsuranceService {

  private path = environment.API_BASE_URL_REGULATION_AUTO + '/lighthouseview';

  constructor(
    private http: HttpClient
  ) { }

  /**
   * 
   * @returns {Observable<any>}
   * @memberof LightHouseInsuranceService
   */
  getAll() {
    return this.http.get(`${this.path}/getlighthousebyinsurance`);
  }

  /**
   * 
   * @param insuranceid 
   * @param lightHouse 
   * @returns {Observable<any>}
   */
  getAllSinister(insuranceid: string, lightHouse: number) {
    return this.http.get(`${this.path}/getlighthousecard/insurance/${insuranceid}/lighthouse/${lightHouse}`);
  }

}
