<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body">
  <h4 *ngIf="message" style="text-align: center;">
    <svg *ngIf="icon" cIcon size="xl" [name]="icon"></svg>
    {{ message }}
  </h4>
  <p *ngIf="complementaryMessage" style="text-align: center; padding-top: 7px;padding-bottom: 7px;border-radius: 5px;" class="dialog-complementary-message">
    <span>{{ complementaryMessage }}</span>
  </p>
  <p *ngIf="subMessage" style="text-align: center;"> 
    {{ subMessage }} 
  </p>
  <h2 class="pt-2">
    {{ information }}
  </h2>
</div>
<div class="modal-footer">
  <button cButton color="danger" (click)="decline()" *ngIf="btnCancelText">
    <svg cIcon name="cilX"></svg>
    {{ btnCancelText }}
  </button>
  <button cButton color="primary" (click)="accept()" *ngIf="btnOkText">
    <svg cIcon name="cilCheckAlt"></svg>
    {{ btnOkText }}
  </button>
</div>