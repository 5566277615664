<!--sidebar-->
<c-sidebar
  #sidebarEmail="cSidebar"
  [visible]="true"
  class="d-print-none"
  id="sidebarEmail"
>
  <c-sidebar-brand
    [brandFull]="{
      src: 'assets/img/brand/coreui-pro-angular-white.svg',
      width: 190,
      height: 35,
      alt: 'CoreUI Logo'
    }"
    [brandNarrow]="{
      src: 'assets/img/brand/coreui-signet-white.svg',
      width: 46,
      height: 46,
      alt: 'CoreUI Logo'
    }"
    href
  >
  </c-sidebar-brand>
  <c-sidebar-nav
    [navItems]="navItems"
    [perfectScrollbar]="perfectScrollbarConfig"
  >
  </c-sidebar-nav>
  <c-sidebar-toggler
    toggle="unfoldable"
    cSidebarToggle="sidebarEmail"
  ></c-sidebar-toggler>
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <!--app-header-->
  <app-default-header
    class="mb-4 d-print-none"
    position="sticky"
    sidebarId="sidebarEmail"
  ></app-default-header>
  <!--app body-->
  <div class="body flex-grow-1 px-3">
    <c-container breakpoint="lg" class="h-auto">
      <router-outlet></router-outlet>
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer></app-default-footer>
</div>

<!--aside-->
<app-default-aside></app-default-aside>
