import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { lightHouseType } from 'src/app/shared/constants/light-house-type';
import { LightHouseInsuranceService } from 'src/app/shared/service/light-house-insurance.service';
import { RegulationAutoService } from 'src/app/views/pages/regulation/auto/service/regulation-auto.service';

@Component({
  selector: 'app-default-aside',
  templateUrl: './default-aside.component.html',
  styleUrls: ['./default-aside.component.scss'],
  providers: [RegulationAutoService]
})
export class DefaultAsideComponent implements AfterViewInit, OnInit {
  
  public readonly lightHouseType = lightHouseType;

  public insuranceList: Array<any> = new Array<any>;
  public sinisterList: Array<any> = new Array<any>;
  public messages = new Array(6);

  @ViewChild('aside2Button', { static: false }) aside2Button: ElementRef<HTMLButtonElement> | undefined;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private lightHouseInsuranceService  : LightHouseInsuranceService,
    private regulationAutoService       : RegulationAutoService,
    private spinnerService              : NgxSpinnerService,
    private toastr                      : ToastrService,
    private router                      : Router,
  ) {}
  
  ngOnInit(): void {
    this.lightHouseInsuranceService.getAll()
      .subscribe((data: any) => {
        this.insuranceList = data.data;
      });
  }

  ngAfterViewInit(): void {
    this.renderer.removeStyle(this.elementRef.nativeElement, 'display');
  }

  openInsurances(insuranceid: string, lightHouse: number) {
    this.spinnerService.show();
    this.lightHouseInsuranceService.getAllSinister(insuranceid, lightHouse)
      .subscribe({ 
        next: (data: any) => {
          this.sinisterList = data.data;
          this.aside2Button?.nativeElement.click();
          this.spinnerService.hide();
        }, 
        error: (erro: HttpErrorResponse) => { 
          this.spinnerService.hide();
          this.toastr.error(erro.statusText, "Erro")
        }
    })  
  }

   /**
   * 
   * @param item 
   */
  /**
   * 
   * @param regulationAutoId 
   */
   openRouter(sinister: any) {
    
    let data = {
      "insurance": [],
      "insured": "",
      "stage": [],
      "sinisterCode": sinister.sinisterCode,
      "sinisterVersion": sinister.sinisterVersion,
      "expert": [],
      "sinisterDateStart": null,
      "sinisterDateEnd": null,
      "plate": "",
      "ranking": null,
      "regulationType": [],
      "schedulingType": [],
      "budgetSystem": [],
      "product": [],
      "lighthouse": [],
      "analyst": [],
      "schedulingDateStart": null,
      "schedulingDateEnd": null
    };

    this.regulationAutoService.search2(data, 10, 1)
      .subscribe({ 
        next: (data: any) => {
          this.spinnerService.hide();
          if (data.data) {
            let itemOpen = null;
            for (let iterator of data.data) {
              if (sinister?.regulationAutoId == iterator?.id) {
                itemOpen = iterator;
                break;
              }              
            }

            if (itemOpen) {
              sessionStorage.setItem(itemOpen.id, JSON.stringify(itemOpen));
              this.router.navigate(['/regulacao/sinistros', itemOpen.id]);
            } else {
              this.toastr.warning("Sinistro não encontrado.", "Atenção!");
            }             
          }
        }, 
        error: (erro: HttpErrorResponse) => { 
          this.spinnerService.hide();
          this.toastr.error(erro.statusText, "Erro")
        }
    });
  }

  getColor(lightHouse: number): string {
    switch (lightHouse) {
      case lightHouseType.GREEN:
        return 'green !important';
      case lightHouseType.YELLOW:
        return 'yellow !important';
      case lightHouseType.RED:
        return 'red !important';
      case lightHouseType.BLUE:
        return 'blue !important';
      case lightHouseType.BLACK:
        return 'black !important';
      default:
        return '#999 !important'
    }
  }
}
