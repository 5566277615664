import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { ClassToggleService, HeaderComponent } from '@coreui/angular-pro';
import { AccountService } from 'src/app/shared/service/account.service';
import { jwt } from 'src/app/shared/constants/jwt';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Select, Store } from '@ngxs/store';
import { UserState } from 'src/app/shared/state/user-logger.state';
import { Observable } from 'rxjs';
import { SetUserLogger } from 'src/app/shared/action/user-logger.actions';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent implements OnInit {

  @Input() sidebarId: string = "sidebar1";

  @Select(UserState.selectStateData) user$: Observable<any> | undefined;

  public userLogged: any = null;
  public iniciaisNome: string = "";
  public urlImage: string = "";

  public themeSwitch = new UntypedFormGroup({
    themeSwitchRadio: new UntypedFormControl(localStorage.getItem(jwt.THEME_MODE) === 'dark' ? 'dark' : 'light'),
  });

  constructor(
    private classToggler  : ClassToggleService,
    private accountService: AccountService,
    private store         : Store) {
    super();
  }

  setTheme(value: string): void {
    this.themeSwitch.setValue({ themeSwitchRadio: value });
    this.classToggler.toggle('body', 'dark-theme');
    localStorage.setItem(jwt.THEME_MODE, localStorage.getItem(jwt.THEME_MODE) === 'dark' ? 'light' : 'dark')
    this.accountService.updateTheme(this.userLogged.id, localStorage.getItem(jwt.THEME_MODE) === 'dark' ? true : false)
      .subscribe({  next: (data) => { },  error: (erro: HttpErrorResponse) => { }});
  }

  ngOnInit(): void {
    this.accountService.logged()
      .subscribe(
        (user: any) => {
          this.store.dispatch(new SetUserLogger(user.data))
          if (this.userLogged?.isDarkTheme) {
            this.classToggler.toggle('body', 'dark-theme');
            localStorage.setItem(jwt.THEME_MODE, 'dark')
          }
        }
      ); 

      this.user$?.subscribe((user :any) => {
        if (user) {
          this.userLogged = user;
          this.iniciaisNome = this.getSplitIniciaisNome(this.userLogged?.name)
        }
      })
  }

  getDataImageBase64(photo: any): string {
    return `data:${this.userLogged.imageContentType};base64,${this.userLogged.imageBase64}`
  }

  defaultTheme() {
    if (localStorage.getItem(jwt.THEME_MODE) === 'dark') {
      this.classToggler.toggle('body', 'dark-theme');
    }
  }

  getSplitIniciaisNome(nome: string): string {
    let iniciais = "";

    if (nome) {
      let palavras = nome.split(' ');
      for (let index = 0; index < palavras.length; index++) {
        const element = palavras[index];
        if (index < 3) {
          iniciais += palavras[index].charAt(0).toUpperCase()
        }
        
      }
    }
    return iniciais;
  }
}
