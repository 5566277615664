import { Pipe, PipeTransform } from '@angular/core';
 
@Pipe({
	name: 'confirmacao'
})
export class ConfirmacaoPipe implements PipeTransform {

	transform(confirmacao: string): string {
 		if (confirmacao == null || confirmacao == undefined || confirmacao == '') {
            return 'Não';
        } else {
            return 'Sim';
        }
	}
}